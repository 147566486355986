<template>
  <v-dialog
    v-model="show"
    :fullscreen="isPhone"
    width="800"
    overlay-opacity=".95"
    persistent
  >
    <v-card class="charcoal d-flex flex-column noscroll-x">
      <BaseCloseDialogHeader
        heading="Calculate & Save 1RM"
        @cancel="$emit('repmax_cancel')"
      />
      <v-sheet rounded class="transparent mx-2 mb-4" :style="charcoalTile">
        <v-card-text class="title paper--text pt-2 pb-0 px-2">
          {{ exercise.name }}
        </v-card-text>
        <v-card-text class="caption silver--text px-2">
          Enter the weight lifted and the number of repetitions. We will
          calculate the 1RM for as well as 1RM rounded to the nearest 5 and
          nearest 10. Click
          <span class="progressActive--text"> Done </span> to save the 1RM value
          for this exercise.
        </v-card-text>
      </v-sheet>
      <v-card-text class="px-0">
        <v-sheet
          rounded
          color="transparent mx-2 px-2"
          :style="charcoalTileMenu"
        >
          <v-row dense>
            <v-col cols="6" md="3">
              <v-switch
                dark
                v-model="metric"
                :label="'Metric?'"
                @change="switchMe"
                color="progressActive"
              />
            </v-col>
            <v-spacer />
            <v-col cols="5" md="4">
              <v-text-field
                dark
                label="Calculated 1RM"
                disabled
                color="progressActive--text"
                v-model="oneRM"
                readonly
                class="enlarged-input progressActive--text"
                >{{ oneRM }}</v-text-field
              >
            </v-col>
          </v-row>
          <v-row dense>
            <v-spacer />
            <v-col cols="6" md="3">
              <v-text-field
                dark
                class="enlarged-input-small"
                :label="'Weight Lifted (' + unit(metric) + ')'"
                ref="weight"
                v-model="weight"
                type="number"
                prepend-icon="mdi-weight"
                tabindex="1"
                @input="calculate"
                @focus="$event.target.select()"
              />
            </v-col>
            <v-col cols="6" md="3">
              <v-text-field
                dark
                class="enlarged-input-small"
                label="Repetitions"
                ref="reps"
                v-model="reps"
                type="number"
                prepend-icon="mdi-counter"
                tabindex="2"
                @input="calculate"
              />
            </v-col>
          </v-row>
        </v-sheet>
        <v-card-actions class="paper--text">
          <span :class="textSize">1 RM </span>
          <v-spacer />
          <span :class="textSize + ' progressActive--text pt-2'">
            {{ oneRM }} {{ unit(metric) }}
          </span>
        </v-card-actions>
        <v-card-actions class="paper--text">
          <span :class="textSize">Nearest 5 </span>
          <v-spacer />
          <span :class="textSize + ' progressActive--text pt-2'">
            {{ oneRMFive }} {{ unit(metric) }}
          </span>
        </v-card-actions>
        <v-card-actions class="paper--text">
          <span :class="textSize">Nearest 10 </span>
          <v-spacer />
          <span :class="textSize + ' progressActive--text pt-2'">
            {{ oneRMTen }} {{ unit(metric) }}
          </span>
        </v-card-actions>
      </v-card-text>
      <v-spacer />
      <v-card-actions class="px-0">
        <BaseActionButton
          icon="mdi-close"
          label="Cancel"
          plain
          color="silver"
          @clickedThis="$emit('repmax_cancel')"
        />
        <v-spacer />
        <BaseActionButton
          color="paper"
          :disabled="!(oneRM > 0 && reps > 0)"
          icon="mdi-checkbox-outline"
          label="Done"
          text
          dark
          :class="
            !(oneRM > 0 && reps > 0) ? 'charcoal--text' : 'charcoal paper--text'
          "
          @clickedThis="saveRepMax"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import onermTypes from '@/json/onermtypes.json'
import util from '@/mixins/util.js'

export default {
  name: 'RepMaxDialog',
  mixins: [util],
  data() {
    return {
      metric: false,
      weight: 0,
      reps: 0,
      oneRM: 0,
      oneRMFive: 0,
      oneRMMetric: 0,
      oneRMFiveMetric: 0,
      oneRMImperial: 0,
      oneRMFiveImperial: 0,
      oneRMTen: 0,
      onermTypes: onermTypes
    }
  },
  props: {
    exercise: {
      type: Object
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    textSize() {
      return this.isPhone ? 'title' : 'display-1'
    },
    ...appConfig
  },
  methods: {
    switchMe() {
      this.weight = 0
      this.$nextTick(() => this.$refs.weight.focus())
    },
    calculate() {
      let rm = this.calculateRM(this.reps, this.weight, this.metric)
      this.oneRM = rm.oneRM
      this.oneRMFive = rm.oneRMFive
      this.oneRMTen = rm.oneRMTen
      this.oneRMMetric = rm.oneRMMetric
      this.oneRMFiveMetric = rm.oneRMFiveMetric
      this.oneRMImperial = rm.oneRMImperial
      this.oneRMFiveImperial = rm.oneRMFiveImperial
    },
    oneRMPercentage(val) {
      let calc = this.oneRM * (val / 100)
      calc = Math.round(calc / 5) * 5
      return calc
    },
    saveRepMax() {
      {
        return axios
          .post(this.baseURL + '/exercises/repmax', {
            id: this.exercise.id,
            metric: this.oneRMMetric,
            imperial: this.oneRMImperial,
            metricfive: this.oneRMFiveMetric,
            imperialfive: this.oneRMFiveImperial,
            now: this.now()
          })
          .then(response => {
            if (response.status == 200) {
              this.$emit('repmax_done')
              this.$store.dispatch(
                'notification/addNotification',
                '1RM for ' + this.exercise.name + ' added succesfully.',
                2000,
                true,
                {
                  root: true
                }
              )
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
